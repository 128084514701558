import React from "react";
import "./appCard.scss";
import {App} from "./Apps.model";

interface IAppCard {
    app: App;
};

export const AppCard = ({ app }: IAppCard): JSX.Element => {
    
    return (
        <>
        <div className="appcard">
             <img className="appcard__icon" src={app.icon} alt="Logo"/>
             <div className="appcard__describ">
               <p className="appcard__describ__name">Title: <b>{app.name}</b></p> 
               <h5 className="appcard__describ__info">Description: {app.info}</h5> 
             </div>
        </div>
        </>
    );
};