import React, { useState } from "react";
import "./contact.scss";
import Phone from "./phone.png";
import Email from "./email.png";
import Location from "./location.png";

export const Contact = (): JSX.Element => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="contact">
             <h1 className="contact__header">Contact Me</h1>

             <form className="contact__form" action="https://formspree.io/f/xovadzad" method="POST">
                <h3 className="contact__form__title"> Send Me a Email: </h3>
                <div className="contact__form__name">
                    <label className="contact__form__name__label" htmlFor="name">Name:</label>
                    <input className="contact__form__name__input" type="text" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder="Enter your name" />
                </div>
                <div className="contact__form__name">
                    <label className="contact__form__name__label" htmlFor="email">Email:</label>
                    <input className="contact__form__name__input" type="email" id="email" name="email" value={formData.email} onChange={handleChange} required placeholder="Enter your email" />
                </div>
                <div className="contact__form__name">
                    <label className="contact__form__name__label" htmlFor="message">Message:</label>
                    <textarea className="contact__form__name__input2" id="message" name="message" value={formData.message} onChange={handleChange} required placeholder=" Enter your message ..." />
                </div>
                <div className="contact__form__btn">
                    <button className="contact__form__btn__b" type="submit">Send Message</button>
                </div>
            </form>

             <div className="contact__body">

                <div className="contact__body__phone">
                 <img src={Phone} alt="" className="contact__body__phone__img" />
                 <div className="contact__body__phone__info">
                    <h2 className="contact__body__phone__info__title"> Phone: </h2>
                    <p className="contact__body__phone__info__p"><b>+90-5057514889</b></p>
                    <a href="https://wa.me/+905057514889">
                    <b className="contact__body__phone__info__p">Whatsapp</b>
                    </a>
                 </div>
                </div>

                <div className="contact__body__email">
                 <img src={Email} alt="" className="contact__body__email__img" />
                 <div className="contact__body__email__info">
                    <h2 className="contact__body__email__info__title"> Email: </h2>
                    <p className="contact__body__email__info__p"><b>shassanizorgabad22@ku.edu.tr</b></p>
                    <p className="contact__body__email__info__p"><b>sahandhassani@gmail.com</b></p>
                 </div>
                </div>

                <div className="contact__body__location">
                 <img src={Location} alt="" className="contact__body__location__img" />
                 <div className="contact__body__location__info">
                    <h2 className="contact__body__location__info__title"> Address: </h2>
                    <div className="contact__body__location__info__p">
                        <p>Merkez Mahallesi.</p>
                        <p>Perihan sokak N:125/D:15</p>
                        <p>Şişli 34360</p>
                        <b>Istanbul, Turkey</b>
                    </div>
                 </div>
                </div>
             </div>
             
        </div>
    );
};