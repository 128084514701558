import React from "react";
import "./body.scss";
import { Route , Routes } from "react-router-dom";
import {Contact} from './pages/contact/Contact';
import {Project} from './pages/project/Project';
import {Web} from './pages/web/Web';
import {CV} from './pages/cv/CV';
import {Certificate} from './pages/certificate/Certificate';
import {Home} from './pages/home/Home';

export const Body = (): JSX.Element => {
    return (
        <div className="body">    
            <div className="body_main">
                <div className="body_main_main">
                    <Routes>
                        <Route path='/contact' element= {<Contact/>}/>
                        <Route path='/projects' element= {<Project/>}/>
                        <Route path='/web' element= {<Web/>}/>
                        <Route path='/cv' element= {<CV/>}/>
                        <Route path='/certificates' element= {<Certificate/>}/>
                        <Route path='/' element= {<Home/>}/>
                    </Routes>
                </div>  
            </div>       
        </div>
    );
};