import React from "react";
import "./footer.scss";
import Github from "./github.png";
import Facebook from "./facebook.png";
import Instagram from "./instagram.png";
import Whatsapp from "./whatsapp.png";

export const Footer = (): JSX.Element => {
    return (
        <div className="footer">
            <div className="footer__copyright">
                <p className="footer__copyright-p">
                    &copy; 2024 All Rights Reserved by <span className="footer__copyright-p__span">   SAHANDHASSANI.COM</span>
                </p>
            </div>
            <div className="footer__links">
                <a href="https://instagram.com/Sahandhassani" className="footer__link">
                    <img src={Instagram} alt="" className="footer__link__img" />
                    Instagram
                </a>
                <a href="https://www.facebook.com/sahand.hs" className="footer__link">
                    <img src={Facebook} alt="" className="footer__link__img" />
                    Facebook
                </a>
                <a href="https://github.com/Sahandhassani" className="footer__link">
                    <img src={Github} alt="" className="footer__link__img" />
                    Github
                </a>
                <a href="https://wa.me/+905057514889" className="footer__link">
                    <img src={Whatsapp} alt="" className="footer__link__img" />
                    Whatsapp
                </a>
              

                
            </div>
        </div>
    );
};