import React from "react";
import "./web.scss";
import icon1 from './icon2.png';
import icon2 from '../../../header/Icon.png';
import icon3 from './MISOL.png';

export const Web = (): JSX.Element => {
    return (
        <div className="web">
            <h2 className="web__header">WEB SITEs</h2>
            <div className="web__body">
                    <div className="web__body__card" >
                        <div className="web__body__card__intro">
                        <a href="https://medusatrade.com">
                           <h4>www.medusatrade.com</h4>
                        </a>
                        <p>AI based algorithmic trading platform</p>
                        </div>

                        <div className="web__body__card__img">
                           <img className="web__body__card__img__icon" src={icon1} alt="medusatrade Icon" />
                        </div>
                    </div>

                    <div className="web__body__card" >
                        <div className="web__body__card__intro">
                        <a href="https://sahandhassani.com">
                            <h4>www.sahandhassani.com</h4>
                        </a>
                        <p>My personal web page</p> 
                        </div>

                        <div className="web__body__card__img">
                           <img className="web__body__card__img__icon" src={icon2} alt="sahandhassani Icon" />
                        </div>
                    </div>

                    <div className="web__body__card" >
                        <div className="web__body__card__intro">
                            <h4>www.luciddream.com</h4>
                            <p>Coming soon . . .</p>  
                        </div>
                        
                        <div className="web__body__card__img">
                           <img className="web__body__card__img__icon" src={icon3} alt="luciddream Icon" />
                        </div>
                    </div>
             </div> 
        </div>
    );
};