import React, { useEffect, useState } from "react";
import "./certificate.scss";
import {Cer} from "./Cer.mock";
import { CerCard} from "./CerCard";
import { CerModel } from './Cer.model';

export const Certificate = (): JSX.Element => {
    const [ cerli , setCer] = useState<CerModel[]>([]);
                              
    return (
        <div className="certificate">
             <h2 className="certificate__title">Certifiations</h2>
             <div className="certificate__wrapper">
                 {Array.isArray(Cer) && Cer.length > 0 && Cer.map((o,i) => (
                    <div key={i} className="certificate__card" >
                        <CerCard cer={o} />
                    </div>
                ))}
             </div>    
        </div>
    );
};