import React from "react";
import "./cerCard.scss";
import {Cer} from "./Cer.model";

interface ICerCard {
    cer: Cer;
};

export const CerCard = ({ cer }: ICerCard): JSX.Element => {
    
    return (
        <>
        <div className="cercard">
             <img className="cercard__cer" src={cer.certification} alt="Certificate"/>
        </div>
        </>
    );
};