import React from "react";
import "./skill.scss";

interface SkillProps {
    name: string;
    level: number; // Skill level as a percentage (0 to 100)
  }
  
  const Skill: React.FC<SkillProps> = ({ name, level }) => {
    return (
      <div className="skill">
        <div className="skill__name">{name}</div>
        <div className="skill__bar">
          <div className="skill__level" style={{ width: `${level}%` }}>
            {level}%
          </div>
        </div>
      </div>
    );
  };
  
  export default Skill;