import React from "react";
import "./home.scss";
import Sahand from  "./sahandhassani.png";
import Skill from "./Skill";
import {Link} from "react-router-dom";

export const Home = (): JSX.Element => {

    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = `${process.env.PUBLIC_URL}/CV.pdf`;
      link.download = 'CV.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
      
    return (
        <div className="home">
          <div className="home__head">
            <div className="home__head__left">
               <h1 className="home__head__left__h1">I'm <b className="home__head__left__h1__name">Sahand</b> Hassani</h1>
               <h3 className="home__head__left__h3">Data Scientist </h3>
               <button className="home__head__left__btn" onClick={handleDownload}>Download CV</button>
               
            </div>
            <img className="home__head__sahand" src={Sahand} alt='sahand' />
          </div>

          <div className="home__about">
            <h3 className="home__about__h3">About Me :</h3>
            <h4 className="home__about__h4"> Since my childhood, I have been interested in mathematics and computer games, which led me to pursue a Bachelor of Science in Computer Software Engineering and a Master of Science in Data Science. I am dedicated to investing my life in AI and am open to being involved in any kind of data science projects. My dream is to become a professor in this field one day.
                  Currently, I am launching MedusaTrade, an AI-based algorithmic trading service, at <a href="https://kworks.ku.edu.tr/">KWORKS</a>.
            </h4>
          </div>

          <div className="home__skill">
            <h3 className="home__skill__h3">My Skills :</h3>
            <div className="home__skill__skil">
                <Skill name="Python" level={95} />
                <Skill name="JavaScript" level={75} />
                <Skill name="React" level={80} />
                <Skill name="Machine Learning" level={90} />
                <Skill name="Deep Learning" level={85} />
                <Skill name="Visualization" level={90} />
                <Skill name="Big Data" level={80} />
                <Skill name="NLP" level={85} />
                <Skill name="Cloud Computing" level={70} />
                <Skill name="Version Control" level={80} />
                <Skill name="MongoDB" level={80} />
                <Skill name="Data Analysis" level={95} />
              </div>
          </div>

          <div className="home__service">
            <h3 className="home__service__h3">Services I'll Provide :</h3>
            <div className="home__service__serv" >
              <div className="home__service__serv__s">
                  <b>Data Science Projects:</b>  
                <h4 className="home__service__serv__h4">
                  I can build end-to-end data science projects, including fetching data, data preprocessing, training machine learning models, and implementing these projects on cloud platforms.
                </h4>
              </div>
              <div className="home__service__serv__s">
              <b>Research Papers:</b> 
                <h4 className="home__service__serv__h4">
                  Since nearly all research requires data and processing, I can use my analytics and research methodology knowledge to be involved in research papers.
                </h4>
              </div>
              <div className="home__service__serv__s">
              <b>Web Development:</b> 
                <h4 className="home__service__serv__h4">
                  Building websites with React, Node.js, and MongoDB technologies is one of my hobbies, and I enjoy creating a variety of websites.
                </h4>
              </div>
              <div className="home__service__serv__s">
              <b>Founder Engineer:</b> 
                <h4 className="home__service__serv__h4">
                I am enthusiastic about building MVPs for my startup ideas and writing their business plans. Additionally, I have an algorithmic trading system that can help early-stage startups finance themselves.
                </h4>
              </div>
            </div>
          </div>

          <div className="home__contact">
            <Link className="home__contact__link" to={'/contact'}>Contact Me</Link>
          </div>

            
        </div>
    );
};