import './App.scss';
import React from "react";
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { Body } from './components/body/Body';
import { BrowserRouter as Router } from 'react-router-dom';

const App = (): JSX.Element => {

  return (
  <Router>  
    <div className="App">
      <div className="App__header">
        <Header/>
      </div>
      <div className="App__body">
        <Body/>
      </div>
      <div className="App__footer">
        <Footer/>
      </div>
    </div>
    </Router>
  );
}

export default App;
