import React from "react";
import "./header.scss";
import icon from "./Icon.png";
import {Link} from "react-router-dom";
          
export const Header = (): JSX.Element => {
    return (
        <div className="header">
            <div className="header__menu">
                     
                    <div className="header__menu__li7">
                     <img className="header__menu__li7__icon" src={icon} alt="icon"/>
                    </div>
                    <Link className="header__menu__li6" to={'/'}>Home</Link>
                    <Link className="header__menu__li4" to={'/cv'}>CV</Link>
                    <Link className="header__menu__li2" to={'/projects'}>Projects</Link>
                    <Link className="header__menu__li5" to={'/certificates'}>Certificates</Link>
                    <Link className="header__menu__li3" to={'/web'}>WebApps</Link>
                    <Link className="header__menu__li1" to={'/contact'}>Contact</Link>
            
            </div>   
        </div>
    );
};