import React, {useEffect, useRef ,useState} from "react";
import "./project.scss";
import { Link } from "react-router-dom";
import {apps} from "./apps.mock";
import { AppCard} from "./AppCard";
import { useGlobalState } from "../../../../hooks/useGlobalState";
import { Modal } from '../../../modal/Modal';
import { useClickAway } from "react-use";
import  axios  from 'axios';
import { AppModel } from './Apps.model';

export const Project = (): JSX.Element => {
    const { setShowModal , showModal , setErrorOccured} = useGlobalState();
    const [ activeDreamIndex, setActiveDreamIndex ] = useState<number>(-1);
    const ref = useRef(null);
    const [ appli , setApp] = useState<AppModel[]>([]);
                                          
    useEffect(() => {
        getApps();
    }, []);
    
    const getApps = () => {
        axios.get('http://localhost:9000/api/sandman/apps/getAll').then(result => {
            setApp(result.data);
            console.log(result.data);
            console.log(apps);
            console.log(appli);
        }).catch(error => {
          setErrorOccured(true, error.response.data.message);
          setTimeout(() => {
            setErrorOccured(false , '');
          },3000);
        });
    };

    useClickAway(ref , ()=> {
        setShowModal(false);
        resetAppsIndex();
    });
    const showAppModal = (index: number) => {
        setActiveDreamIndex(index);
        setShowModal(true);
    };
    const resetAppsIndex = () => {
        setActiveDreamIndex(-1);
    };


    return (
        <>
        <div className="project">
             <h2 className="project__title">Projects</h2>
             <div className="project__wrapper">
                 {Array.isArray(apps) && apps.length > 0 && apps.map((o,i) => (
                    <div key={i} className="project__card"  onClick={() => showAppModal(i)}>
                        <AppCard app={o} />
                    </div>
                ))}
             </div>    
        </div>
        
        {showModal && activeDreamIndex>-1 && (
            <Modal ref={ref}>
                 <div className="modal__appcard">
                 <p className="modal__appcard__name">{apps[activeDreamIndex].name}</p> 
                     <Link className="modal__appcard__icon" to={'/app/Misol'} > 
                         <img className="modal__appcard__icon" src={apps[activeDreamIndex].icon} alt="Logo"/>
                     </Link>                    
                     <h5 className="modal__appcard__info"><b>Description:</b> {apps[activeDreamIndex].info}</h5>           
                 </div>
            </Modal>
        )}
        </>
    );
};

